import Vue from 'vue'

export default{
	namespaced: true,
	state: () => ({
		0: {
			day: 'mo',
			meal: null,
		},
		1: {
			day: 'tu',
			meal: null,
		},		
		2: {
			day: 'we',
			meal: null,
		},		
		3: {
			day: 'th',
			meal: null,
		},		
		4: {
			day: 'fr',
			meal: null,
		},		
		5: {
			day: 'sa',
			meal: null,
		},
		6: {
			day: 'su',
			meal: null,
		},
		list: [0,1,2,3,4,5,6],		
	}),
	getters: {
		selectedMeals: function(state){
			let tmp = [];

			for(let i = 0; i < state.list.length; i++){
				tmp.push(state[state.list[i]].meal);
			}

			return tmp;
		},
	},
	mutations: {
		setMealForDay: function(state, payload){
			for(let i = 0; i < state.list.length; i++){
				if(state[state.list[i]].day === payload.day){
					Vue.set(state[state.list[i]], 'meal', payload.meal);
				}
			}
		},
	},
}