<template>
	<div class="container-fluid">
		<div class="row mt-3">
			<div class="col">
				<!-- <label for="form-label startDay sr-only visually-hidden">Start dag: </label> -->
				<select name="" id="" class="custom-select" @input="onChangeStartDay">
					<option disabled selected value="">Kies een andere start dag</option>
					<option v-for="day in days.list" :key="day" :value="day">{{ days[day].label }}</option>
				</select>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col">
				<dl class="list-group">
					<day v-for="day in dayOrder" :key="day" :day="week[day]" />
				</dl>		
			</div>
		</div>
		<div class="row mt-3">
			<div class="col">
				<button type="button" class="btn btn-link" @click="showIngredients = !showIngredients">Toon ingredi&euml;nten</button>
			</div>
		</div>
		<div v-if="showIngredients" class="row mt-3">
			<div class="col">
				<ul class="list-group list-group-flush">
					<li v-for="key in Object.keys(ingredients)" class="list-group-item d-flex justify-content-between align-items-center p-1" :key="key">
						{{ ingredients[key].label }}
						<span v-if="ingredients[key].count > 1" class="badge badge-success badge-pill">{{ ingredients[key].count }}</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col d-flex flex-column justify-content-end">
				<button type="button" class="btn btn-success" @click="onGenerate">Genereren</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import { mapGetters } from 'vuex'
	import { mapMutations } from 'vuex'
	import Day from './components/day.vue'

	export default{
		data: function(){
			return {
				dayOrder: [0,1,2,3,4,5,6],
				showIngredients: false,
			};
		},
		components: {
			'day': Day,
		},
		computed: {
			...mapState({
				week: 'week',
				days: 'days',
				meals: 'meals',
			}),
			...mapGetters({
				selectedMeals: 'week/selectedMeals',
			}),
			ingredients: function(){
				let tmp = [];

				// Loop through the week.
				for(let i = 0; i < this.dayOrder.length; i++){
					// If no meal is set for this day, just continue.
					if(this.week[this.dayOrder[i]].meal === null){
						continue;
					}

					// Loop through the ingredients of the meal of a specific day within the week.
					for(let j = 0; j < this.meals[this.week[this.dayOrder[i]].meal].ingredients.length; j++){
						let tmpIngredient = this.meals[this.week[this.dayOrder[i]].meal].ingredients[j];
						if(tmp[tmpIngredient.toLowerCase()] === undefined){
							this.$set(tmp, tmpIngredient.toLowerCase(), {
								label: tmpIngredient,
								count: 1,
							});
						}
						else{
							this.$set(tmp[tmpIngredient.toLowerCase()], 'count', tmp[tmpIngredient.toLowerCase()].count + 1);
						}
					}
				}

				let tmpIngredients = [];
				for(const key in tmp){
					tmpIngredients.push(tmp[key]);
				}

				tmpIngredients.sort(function(a,b){
					if(a.label > b.label){
						return 1;
					}

					if(a.label < b.label){
						return -1;
					}

					return 0;
				});

				return tmpIngredients;
			},
		},
		methods: {
			...mapMutations({
				setMealForDay: 'week/setMealForDay',
			}),
			onGenerate: function(){
				// Chance per meal.
				let chancePerMeal = 1 / this.meals.list.length;
				
				// Loop through the week.
				for(let i = 0; i < this.week.list.length; i++){
					// Make sure a meal is found for the day.
					let found = false;

					// Select a meal for each day, by looping through the meals.
					for(let j = 0; j < this.meals.list.length; j++){

						let lclChancePerMeal = chancePerMeal;

						// Meals can not be repeated.
						if(this.selectedMeals.indexOf(this.meals.list[j]) > -1){
							continue;
						}

						// Some meals require more effort and time and can only be an option on days that allow this.
						switch(this.days[this.week[this.week.list[i]].day].timeAvailable){
							case 'short':
							case 'medium':
								if(['long'].indexOf(this.meals[this.meals.list[j]].timeToPrepare) > -1 || ['high'].indexOf(this.meals[this.meals.list[j]].effort) > -1){
									continue;
								}
							break;
							case 'long':
								// Increase the chance of a meal which requires more time/effort.
								if(['long'].indexOf(this.meals[this.meals.list[j]].timeToPrepare) > -1 || ['high'].indexOf(this.meals[this.meals.list[j]].effort) > -1){
									lclChancePerMeal *= 2;
								}
							break;
						}

						// Some meals are less frequently offered, so have a lower chance to be selected.
						switch(this.meals[this.meals.list[j]].frequency){
							case 'incidentally':
								if(Math.random() <= ((lclChancePerMeal / 2) / 2)){
									this.setMealForDay({
															day: this.week[this.week.list[i]].day, 
															meal: this.meals.list[j]
														});
									found = true;
								}
							break;
							case 'sometimes':
								if(Math.random() <= (lclChancePerMeal / 2)){
									this.setMealForDay({
															day: this.week[this.week.list[i]].day, 
															meal: this.meals.list[j]
														});
									found = true;
								}
							break;
							case 'average':
								if(Math.random() <= lclChancePerMeal){
									this.setMealForDay({
															day: this.week[this.week.list[i]].day, 
															meal: this.meals.list[j]
														});
									found = true;
								}
							break;
							case 'often':
								if(Math.random() <= (lclChancePerMeal * 2)){
									this.setMealForDay({
															day: this.week[this.week.list[i]].day, 
															meal: this.meals.list[j]
														});
									found = true;
								}
							break;
						}
					}

					if(!found){
						i--;
					}
				}
			},
			onChangeStartDay: function(e){
				let newStartKey = this.days.list.indexOf(e.target.options[e.target.selectedIndex].value);
				this.dayOrder.splice(0, this.dayOrder.length);
				for(let i = 0; i < this.days.list.length; i++){
					if(newStartKey + i >= this.days.list.length){
						this.dayOrder.push((newStartKey + i) - this.days.list.length);
					}
					else{
						this.dayOrder.push(newStartKey + i);
					}
				}
			},
		},
		created: function(){
			if(this.week.list.length > this.meals.list.length){
				console.error('There\'s not enough meals to generate a unique meal for each day.');
			}
		},
	}
</script>

<style lang="scss">
	
</style>