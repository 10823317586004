export default{
	namespaced: true,
	state: () => ({
		0: {
			name: 'Witlof',
			ingredients: [
				'Witlof',
				'Ham',
				'Geraspte kaas',
				'Italiaanse kruiden',
				'Aardappels',
				'Mayonaise',
			],
			timeToPrepare: 'long', // short | medium | long
			effort: 'high', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		1: {
			name: 'Sperziebonen met pindasaus',
			ingredients: [
				'Sperziebonen',
				'Paprika',
				'Kip',
				'Rijst',
				'Pindasaus',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		2: {
			name: 'Sperziebonen, wortels en spekjes',
			ingredients: [
				'Sperziebonen',
				'Paprika',
				'Wortels',
				'Doperwtjes',
				'Spekjes',
				'Aardappels',
				'Geraspte kaas',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		3: {
			name: 'Pizza',
			ingredients: [
				'Glutenvrije pizza',
				'Pizza',
				'Paprika',
				'Tonijn',
				'Geraspte kaas',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'low', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		4: {
			name: 'Pasta',
			ingredients: [
				'Tomatensaus (gezeefde tomaten, gepelde tomaten, tomatenpuree, paprika, oregano, laurierblaadje)',
				'Pasta',
				'Glutenvrije pasta',
				'Krenten (Sultanas)',
				'Gehakt',
				'Courgette',
				'Geraspte kaas',
			],
			timeToPrepare: 'short', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		5: {
			name: 'Patat',
			ingredients: [
				'Patat',
				'Frikadellen/kroketten',
				'Pindasaus',
				'Vissticks',
				'Gehakte spinazie',
				'Rijst',
				'Rode kool met appeltjes',
				'Geraspte kaas',
				'Mayonaise',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		6: {
			name: 'Broccoli pesto pasta',
			ingredients: [
				'Broccoli',
				'Pesto (verse basilicum, pijnboompitten, olijfolie, strooikaas)',
				'Glutenvrije pasta',
				'Pasta',
				'Kip',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'low', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		7: {
			name: 'Quesadilla\'s',
			ingredients: [
				'Wraps',
				'Tomatensaus (gezeefde tomaten, gepelde tomaten, tomatenpuree, paprika, oregano, laurierblaadje)',
				'Broccoli',
				'Gerookte kip',
				'Geraspte kaas',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		8: {
			name: 'Aubergine en courgette met zoete aardappels',
			ingredients: [
				'Aubergine',
				'Courgette',
				'Kip',
				'Pesto (verse basilicum, pijnboompitten, olijfolie, strooikaas)',
				'Tomaten',
				'Zoete aardappels',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'high', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		9: {
			name: 'Ovenschotel met aubergine en courgette',
			ingredients: [
				'Aubergine',
				'Courgette',
				'Tonijn',
				'Bechamelsaus (meel, bakboter, melk)',
				'Tomatensaus (gezeefde tomaten, gepelde tomaten, tomatenpuree, paprika, oregano, laurierblaadje)',
				'Glutenvrije pasta',
				'Pasta',
				'Geraspte kaas',
			],
			timeToPrepare: 'long', // short | medium | long
			effort: 'high', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		10: {
			name: 'Bladspinazie met krenten',
			ingredients: [
				'Bladspinazie',
				'Krenten (Sultanas)',
				'Gehakt',
				'Gekookt ei',
				'Aardappels',
				'Geraspte kaas',
			],
			timeToPrepare: 'short', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'average', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		11: {
			name: 'Kipshoarma',
			ingredients: [
				'Kip',
				'Shoarmakruiden',
				'Pitabroodjes',
				'IJsbergsla',
				'Salade',
				'Tomaten',
				'Paprika',
				'Knoflooksaus',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'sometimes', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		12: {
			name: 'Pannenkoeken',
			ingredients: [
				'Glutenvrij pannenkoekenmeel',
				'Appel',
				'Spek',
				'Poedersuiker',
				'Stroop',
				'Kaneel',
				'Kaas',
			],
			timeToPrepare: 'long', // short | medium | long
			effort: 'long', // low | medium | high
			frequency: 'sometimes', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		// 13: {
		// 	name: 'Spinazie pasta met kip',
		// 	ingredients: [
		// 		'Kip',
		// 		'Tomatensaus (gezeefde tomaten, gepelde tomaten, tomatenpuree, paprika, oregano, laurierblaadje)',
		// 		'Glutenvrije pasta',
		// 		'Pasta',
		// 		'Geraspte kaas',
		// 		'Bladspinazie',
		// 	],
		// 	timeToPrepare: 'medium', // short | medium | long
		// 	effort: 'medium', // low | medium | high
		// 	frequency: 'sometimes', // incidentally | sometimes | average | often
		// 	recentMeal: false,
		// 	recentMealExpiration: null,
		// },
		14: {
			name: 'Lasagne',
			ingredients: [
				'Tomatensaus (gezeefde tomaten, gepelde tomaten, tomatenpuree, paprika, oregano, laurierblaadje)',
				'Glutenvrije lasagnebladen',
				'Courgette',
				'Paprika',
				'Gehakt',
				'Geraspte kaas',
				'Bechamelsaus (meel, bakboter, melk)',
				'Rucola',
				'Pijboompitten',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'sometimes', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		15: {
			name: 'Chorizo pasta met Courgette',
			ingredients: [
				'Courgette',
				'Glutenvrije pasta',
				'Pasta',
				'Chorizo',
				'Paprika',
				'Maïs',
				'Balsamico azijn',
				'Kookroom',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'sometimes', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		16: {
			name: 'Courgettesoep',
			ingredients: [
				'Courgette',
				'Groentebouillon',
				'Mosterd',
				'Spekjes',
				'Glutenvrije ciabatta',
				'Ciabatta',
				'Kookroom',
			],
			timeToPrepare: 'medium', // short | medium | long
			effort: 'medium', // low | medium | high
			frequency: 'sometimes', // incidentally | sometimes | average | often
			recentMeal: false,
			recentMealExpiration: null,
		},
		list: [0,1,2,3,4,5,6,7,8,9,10,11,12,14,15,16],
	}),
}