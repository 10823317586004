import Vue from 'vue'
import Vuex from 'vuex'
import Days from './modules/days.js'
import Meals from './modules/meals.js'
import Week from './modules/week.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
  	'days': Days,
  	'meals': Meals,
  	'week': Week,
  }
})
