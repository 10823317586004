<template>
	<div class="list-group-item d-relative">
		<dt class="ml-5"><label class="day-code">{{ days[day.day].code }}</label>{{ day.meal !== null ? meals[day.meal].name : 'Nog geen maaltijd!' }}</dt>
		<dd class="ml-5 ingredients mb-0" v-if="day.meal !== null">
			<template v-for="(ingredient, index) in meals[day.meal].ingredients">
				{{ ingredient }}<template v-if="index + 1 !== meals[day.meal].ingredients.length">, </template>
			</template>
		</dd>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default{
		name: 'day',
		props: {
			day: {
				type: Object,
				required: true,
			},
		},
		computed: {
			...mapState({
				days: 'days',
				meals: 'meals',
			}),
		},
	}
</script>

<style lang="scss">
	.day-code{
		position: absolute;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--success);
		border-radius: 8px;
		left: 1rem;
		color: white;
		text-transform: uppercase;
	}

	.ingredients{
		text-transform: lowercase;
	}

	.ingredients:first-letter{
		text-transform: uppercase;
	}
</style>