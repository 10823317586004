export default{
	namespaced: true,
	state: () => ({
		'mo': {
			label: 'Maandag',
			code: 'ma',
			timeAvailable: 'medium', // short | medium | long
		},
		'tu': {
			label: 'Dinsdag',
			code: 'di',
			timeAvailable: 'medium', // short | medium | long
		},
		'we': {
			label: 'Woensdag',
			code: 'wo',
			timeAvailable: 'long', // short | medium | long
		},
		'th': {
			label: 'Donderdag',
			code: 'do',
			timeAvailable: 'medium', // short | medium | long
		},
		'fr': {
			label: 'Vrijdag',
			code: 'vr',
			timeAvailable: 'medium', // short | medium | long
		},
		'sa': {
			label: 'Zaterdag',
			code: 'za',
			timeAvailable: 'long', // short | medium | long
		},
		'su': {
			label: 'Zondag',
			code: 'zo',
			timeAvailable: 'long', // short | medium | long
		},
		list: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
	}),
}